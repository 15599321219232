// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-agency-jsx": () => import("./../../../src/templates/agency.jsx" /* webpackChunkName: "component---src-templates-agency-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */)
}

