const website = require('./website')

module.exports = {
  'de-de': {
    active: false,
    default: false,
    path: 'de',
    locale: 'de-de',
    localeName: 'De',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    agency: "Büro",
    projects: "Projekte",
    contact: "Kontakt",
    prev: "Vorheriges Projekt",
    next: "Nächstes Projekt",
    infos: "Informationen",
    close: "Schließen",
    openProject: "Projektseite öffnen",
    download: "Download des Projektdatenblatts",
    localisation: "Standort",
    year: "Jahr",
    program: "Programm",
    type: "Type",
    patrimoine: "Erbe",
    selection: "Auswahl",
    openAll: "Alles öffnen",
    closeAll: "Alles schließen",
    downloadAll: "Alles herunterladen",
    backToTop: "Zurück zum Anfang",
    Musée: "Museum",
    Équipements: "Anlagen",
    Design: "Design",
    Bureaux: "Büroräume",
    Logements: "Wohnungen",
    Réalisé: "Realisiert" ,
    Concours: "Wettbewerb",
    "En cours": "In Bearbeitung",
    Internat: "Internat",
    Collège: "Collège (Gesamtschule)"
  },
  'en-gb': {
    active: false,
    default: false,
    path: 'en',
    locale: 'en-gb',
    localeName: 'En',
    siteLanguage: 'en',
    ogLang: 'en_GB',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    agency: "Agency",
    projects: "Projects",
    contact: "Contact",
    prev: "Previous project",
    next: "Next project",
    infos: "Information",
    close: "Close",
    openProject: "Open project page",
    download: "Download project sheet",
    localisation: "Location",
    year: "Year",
    program: "Programme",
    type: "Type",
    patrimoine: "Heritage",
    selection: "Selection",
    openAll: "Open all",
    closeAll: "Close all",
    downloadAll: "Download all",
    backToTop: "Back to top",
    Musée: "Museum",
    Équipements: "Facilities",
    Design: "Design",
    Bureaux: "Offices",
    Logements: "Housing",
    Réalisé: "Completed",
    Concours: "Competition",
    "En cours": "In progress"
  },
  'fr-fr': {
    active: true,
    default: true,
    path: 'fr',
    locale: 'fr-fr',
    localeName: 'Fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    agency: "Agence",
    projects: "Projets",
    contact: "Contact",
    prev: "Projet précédent",
    next: "Projet suivant",
    infos: "Informations",
    close: "Fermer",
    openProject: "Ouvrir la page projet",
    download: "Télécharger la fiche projet",
    localisation: "Localisation",
    year: "Année",
    program: "Programme",
    type: "Type",
    patrimoine: "Patrimoine",
    selection: "Sélection",
    openAll: "Tout ouvrir",
    closeAll: "Tout fermer",
    downloadAll: "Tout télécharger",
    backToTop: "Retour en haut de page",
    Musée: "Musée",
    Équipements: "Équipements",
    Design: "Design",
    Bureaux: "Bureaux",
    Logements: "Logements",
    Réalisé: "Réalisé",
    Concours: "Concours",
    "En cours": "En cours"
  },
}
